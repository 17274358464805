import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Katastr nemovitostí stručně" />
    <h1>Katastr nemovitostí stručně</h1>

    <p><strong>Katastr nemovitost&iacute;</strong><strong>(KN)</strong> Česk&eacute; republiky je dle z&aacute;kona 256/2013 Sb. veřejn&yacute; seznam, kter&yacute; obsahuje soubor &uacute;dajů o nemovit&yacute;ch věcech (nemovitostech) vymezen&yacute;ch z&aacute;konem zahrnuj&iacute;c&iacute; jejich soupis, popis, jejich geometrick&eacute; a polohov&eacute; určen&iacute; a z&aacute;pis pr&aacute;v k těmto nemovitostem.</p>
    <p>KN je zdrojem informac&iacute; slouž&iacute;c&iacute;:</p>
    <ul>
    <li>k&nbsp;ochraně pr&aacute;v k&nbsp;nemovitostem,</li>
    <li>pro &uacute;čely dan&iacute;, poplatků a jin&yacute;ch peněžit&yacute;ch plněn&iacute;,</li>
    <li>k&nbsp;ochraně životn&iacute;ho prostřed&iacute;,</li>
    <li>k&nbsp;ochraně z&aacute;jmů st&aacute;tn&iacute; pam&aacute;tkov&eacute; p&eacute;če,</li>
    <li>pro rozvoj &uacute;zem&iacute;,</li>
    <li>k&nbsp;oceňov&aacute;n&iacute; nemovitost&iacute;</li>
    <li>a k&nbsp;dal&scaron;&iacute;m &uacute;čelů (vědeck&eacute;, statistick&eacute;, tvorba informačn&iacute;ch syst&eacute;mů aj.).</li>
    </ul>
    <hr /><h2>Z&aacute;kladn&iacute; pojmy katastru nemovitost&iacute;</h2>
    <h3>Pozemek</h3>
    <p><strong>Pozemek</strong> je č&aacute;st zemsk&eacute;ho povrchu oddělen&aacute; od sousedn&iacute;ch č&aacute;st&iacute; hranic&iacute; (&uacute;zemn&iacute; jednotky, katastr&aacute;ln&iacute;ho &uacute;zem&iacute;, vlastnickou atd.). Geometricky a polohově určen&yacute; pozemek, kter&yacute; je zobrazen v&nbsp;katastr&aacute;ln&iacute; mapě a označen parceln&iacute;m č&iacute;slem, se označuje jako <strong>parcela</strong>.</p>
    <p>Pro &uacute;čely katastru nemovitost&iacute; jsou rozli&scaron;ov&aacute;ny předev&scaron;&iacute;m tyto druhy pozemků:</p>
    <ul>
    <li>orn&aacute; půda (k&oacute;d 2)</li>
    <li>chmelnice (k&oacute;d 3)</li>
    <li>vinice (k&oacute;d 4)</li>
    <li>zahrada (k&oacute;d 5)</li>
    <li>ovocn&yacute; sad (k&oacute;d 6)</li>
    <li>trval&yacute; travn&iacute; porost (k&oacute;d 7)</li>
    <li>lesn&iacute; pozemek (k&oacute;d 10)</li>
    <li>vodn&iacute; plocha (k&oacute;d 11)</li>
    <li>zastavěn&aacute; plocha a n&aacute;dvoř&iacute; (k&oacute;d 13)</li>
    <li>ostatn&iacute; plocha (k&oacute;d 14)</li>
    </ul>
    <h3>Nemovitost (nemovit&aacute; věc)</h3>
    <p><strong>Nemovitost (nemovit&aacute; věc) </strong>je dle občansk&eacute;ho z&aacute;kon&iacute;ku pozemek (stavba je br&aacute;na za souč&aacute;st pozemku) a podzemn&iacute; stavby se samostatn&yacute;m &uacute;čelov&yacute;m určen&iacute;m, jakož i věcn&aacute; pr&aacute;va k nim, a pr&aacute;va (např. pr&aacute;vo stavby), kter&aacute; za nemovit&eacute; věci prohl&aacute;s&iacute; z&aacute;kon. Stanov&iacute;-li jin&yacute; pr&aacute;vn&iacute; předpis, že určit&aacute; věc nen&iacute; souč&aacute;st&iacute; pozemku, a nelze-li takovou věc přen&eacute;st z m&iacute;sta na m&iacute;sto bez poru&scaron;en&iacute; jej&iacute; podstaty, je i tato věc nemovit&aacute;.</p>
    <h3>Katastr&aacute;ln&iacute; &uacute;zem&iacute; (K&Uacute;)</h3>
    <p><strong>Katastr&aacute;ln&iacute;m &uacute;zem&iacute;m</strong> (zkratka <strong>K&Uacute;</strong>) se rozum&iacute; technick&aacute; jednotka, kterou tvoř&iacute; m&iacute;stopisně uzavřen&yacute; a v katastru společně evidovan&yacute; soubor nemovitost&iacute;. Katastr&aacute;ln&iacute; &uacute;zem&iacute; jsou vymezena katastr&aacute;ln&iacute;mi hranicemi v r&aacute;mci katastru nemovitost&iacute; a souvisle pokr&yacute;vaj&iacute; cel&eacute; &uacute;zem&iacute; st&aacute;tu. Ke konci dubna 2019 bylo na &uacute;zem&iacute; ČR evidov&aacute;no 13&nbsp;078 K&Uacute;.</p>
    <hr /><h2>Katastr&aacute;ln&iacute; oper&aacute;t</h2>
    <p><strong>Katastr&aacute;ln&iacute; oper&aacute;t </strong>uspoř&aacute;d&aacute;v&aacute; obsah katastru podle K&Uacute;. Jeho souč&aacute;st&iacute; je:</p>
    <ul>
    <li><strong>soubor geodetick&yacute;ch informac&iacute; (SGI)</strong> = katastr&aacute;ln&iacute; mapa a jej&iacute; č&iacute;seln&eacute; vyj&aacute;dřen&iacute;</li>
    <li><strong>soubor popisn&yacute;ch informac&iacute; (SPI)</strong> = &uacute;daje o K&Uacute;, parcel&aacute;ch, budov&aacute;ch, vlastn&iacute;c&iacute;ch, pr&aacute;vech apod.</li>
    <li><strong>sb&iacute;rka listin</strong> = rozhodnut&iacute; org&aacute;nů veřejn&eacute; moci, smlouvy a jin&eacute; listiny, na jejichž z&aacute;kladě byl proveden z&aacute;pis do katastru, &uacute;pln&aacute; zněn&iacute; prohl&aacute;&scaron;en&iacute; vlastn&iacute;ka domu a dohody spoluvlastn&iacute;ků o spr&aacute;vě nemovitosti</li>
    <li><strong>dokumentace</strong> v&yacute;sledků &scaron;etřen&iacute; a měřen&iacute; pro veden&iacute; a obnovu souboru geodetick&yacute;ch informac&iacute;, včetně m&iacute;stn&iacute;ho a pom&iacute;stn&iacute;ho n&aacute;zvoslov&iacute;</li>
    <li><strong>protokoly</strong> o vkladech, z&aacute;znamech, pozn&aacute;mk&aacute;ch, dal&scaron;&iacute;ch z&aacute;pisech, oprav&aacute;ch chyb, n&aacute;mitk&aacute;ch proti obnoven&eacute;mu katastr&aacute;ln&iacute;mu oper&aacute;tu, v&yacute;sledc&iacute;ch revize katastru a o z&aacute;znamech pro dal&scaron;&iacute; ř&iacute;zen&iacute;</li>
    </ul>
    <h3>Obnova katastr&aacute;ln&iacute;ho oper&aacute;tu</h3>
    <p><strong>Obnova katastr&aacute;ln&iacute;ho oper&aacute;tu</strong> je vyhotoven&iacute; nov&eacute;ho souboru geodetick&yacute;ch informac&iacute; (SGI) a nov&eacute;ho souboru popisn&yacute;ch informac&iacute; (SPI) v elektronick&eacute; podobě, kter&eacute; se provede:</p>
    <ul>
    <li>nov&yacute;m mapov&aacute;n&iacute;m,</li>
    <li>přepracov&aacute;n&iacute;m SGI,</li>
    <li>nebo na podkladě v&yacute;sledků pozemkov&yacute;ch &uacute;prav.</li>
    </ul>
    <p>Vlastn&iacute;ci a jin&iacute; opr&aacute;vněn&iacute; mohou během vyložen&iacute; obnoven&eacute;ho katastr&aacute;ln&iacute;ho oper&aacute;tu a ve lhůtě 15 dnů ode dne, kdy skončilo jeho vyložen&iacute;, podat n&aacute;mitky proti obsahu obnoven&eacute;ho katastr&aacute;ln&iacute;ho oper&aacute;tu. O podan&yacute;ch n&aacute;mitk&aacute;ch rozhoduje katastr&aacute;ln&iacute; &uacute;řad. Dnem vyhl&aacute;&scaron;en&iacute; platnosti obnoven&eacute;ho katastr&aacute;ln&iacute;ho oper&aacute;tu se dosavadn&iacute; katastr&aacute;ln&iacute; oper&aacute;t st&aacute;v&aacute; neplatn&yacute;m a nad&aacute;le se použ&iacute;v&aacute; obnoven&yacute; katastr&aacute;ln&iacute; oper&aacute;t.</p>
    <hr /><h2>Druhy katastr&aacute;ln&iacute;ch map</h2>
    <p><strong>Katastr&aacute;ln&iacute; mapa</strong> je mapou velk&eacute;ho měř&iacute;tka (1&nbsp;:&nbsp;1000, nebo 1&nbsp;:&nbsp;2880) a jakožto soubor geodetick&yacute;ch informac&iacute; (SGI) obsahuje polohopis, popis a body polohov&yacute;ch bodov&yacute;ch pol&iacute;.</p>
    <p>Katastr&aacute;ln&iacute; mapy lze v&nbsp;z&aacute;kladu rozli&scaron;it na:</p>
    <ul>
    <li><strong>Mapy analogov&eacute;</strong>, kter&eacute; jsou vedeny v různ&yacute;ch měř&iacute;tk&aacute;ch a kladech mapov&yacute;ch listů, pravidelně se v&scaron;ak skenuj&iacute;.</li>
    <li><strong>Mapy digit&aacute;ln&iacute;</strong>, kter&eacute; se d&aacute;le děl&iacute; na:
    <ul>
    <li><strong>KM-D</strong> = mapa digitalizovan&aacute;; zpravidla vznik&aacute; přepracov&aacute;n&iacute;m z map v&nbsp;měř&iacute;tku 1:2880 v souřadnicov&eacute;m syst&eacute;mu stabiln&iacute;ho katastru (nen&iacute; v klasick&eacute;m souřadnicov&eacute;m syst&eacute;mu); pokud nedoch&aacute;z&iacute; ke vzniku DKM, b&yacute;v&aacute; přev&aacute;děna na KMD</li>
    </ul>
    <ul>
    <li><strong>KMD</strong> = mapa digitalizovan&aacute;; souč&aacute;st ISKN (Informačn&iacute; syst&eacute;m katastru nemovitost&iacute;) a v&nbsp;souřadnicov&eacute;m syst&eacute;mu S-JTSK</li>
    </ul>
    <ul>
    <li><strong>DKM</strong> = digit&aacute;ln&iacute; mapa; souč&aacute;st ISKN, souřadnicov&yacute; syst&eacute;m S-JTSK, vy&scaron;&scaron;&iacute; přesnost, obvykle vznik&aacute; obnovou oper&aacute;tu nov&yacute;m mapov&aacute;n&iacute;m či přepracov&aacute;n&iacute;m map KN v&nbsp;měř&iacute;tku 1&nbsp;:&nbsp;1000 a 1&nbsp;:&nbsp;2000.</li>
    </ul>
    </li>
    </ul>
    <hr /><h2>Př&iacute;stupy do katastru</h2>
    <p>Do katastru nemovitost&iacute; lze s&nbsp;omezen&yacute;mi funkcemi přistupovat <strong>zdarma</strong>, a to na adrese <a href="http://nahlizenidokn.cuzk.cz/">http://nahlizenidokn.cuzk.cz/</a>. Možn&yacute; je i <strong>placen&yacute;</strong> př&iacute;stup do t&eacute;to aplikace KN s&nbsp;pokročil&yacute;mi funkcemi, kter&yacute; je pro org&aacute;ny st&aacute;tn&iacute; spr&aacute;vy poskytov&aacute;n bez&uacute;platně. Mezi tyto funkce patř&iacute; např&iacute;klad v&yacute;pisy z&nbsp;listu vlastnictv&iacute;, ofici&aacute;ln&iacute; kopie katastr&aacute;ln&iacute;ch map, z&iacute;sk&aacute;n&iacute; &uacute;dajů pro geometrick&eacute; pl&aacute;ny aj.</p>
    <p>Požadovan&eacute; informace z&nbsp;KN lze z&iacute;skat i na přep&aacute;žce katastr&aacute;ln&iacute;ch &uacute;řadů, t&eacute;to možnosti v&scaron;ak využ&iacute;v&aacute; č&iacute;m d&aacute;l m&eacute;ně uživatelů na &uacute;kor vzd&aacute;len&eacute;ho, elektronick&eacute;ho př&iacute;stupu přes v&yacute;&scaron;e uvedenou webovou aplikaci.</p>
    <hr /><h2>List vlastnictv&iacute;</h2>
    <p><strong>List vlastnictv&iacute; (LV)</strong> je veřejn&aacute; listina obsahuj&iacute;c&iacute; soupis nemovitost&iacute;, kter&eacute; v dan&eacute;m katastr&aacute;ln&iacute;m &uacute;zem&iacute; vlastn&iacute; konkr&eacute;tn&iacute; vlastn&iacute;k či spoluvlastn&iacute;ci, a slouž&iacute; k prokazov&aacute;n&iacute; tohoto vlastnictv&iacute;. LV se skl&aacute;d&aacute; z&nbsp;těchto č&aacute;st&iacute;:</p>
    <ul>
    <li>Z&aacute;hlav&iacute;</li>
    <li>Č&aacute;st A: &bdquo;Vlastn&iacute;k, jin&yacute; opr&aacute;vněn&yacute;&ldquo;</li>
    <li>Č&aacute;st B: &bdquo;Nemovitosti&ldquo;</li>
    <li>Č&aacute;st B1: &bdquo;Jin&aacute; pr&aacute;va&ldquo;</li>
    <li>Č&aacute;st C: &bdquo;Omezen&iacute; vlastnick&eacute;ho pr&aacute;va&ldquo;</li>
    <li>Č&aacute;st D: &bdquo;Jin&eacute; z&aacute;pisy&ldquo;</li>
    <li>Č&aacute;st E: &bdquo;Nab&yacute;vac&iacute; tituly a jin&eacute; podklady k z&aacute;pisu&ldquo;</li>
    <li>Č&aacute;st F: &bdquo;Vztah bonitovan&yacute;ch půdně ekologick&yacute;ch jednotek (BPEJ) k parcel&aacute;m&ldquo;</li>
    </ul>
    <p>V př&iacute;padě, že někter&aacute; z nemovitost&iacute; zapsan&yacute;ch v č&aacute;sti B tohoto LV je dotčena aktu&aacute;ln&iacute; změnou pr&aacute;vn&iacute;ch vztahů, je zde označena p&iacute;smenem P (&bdquo;plomba&ldquo;) a na konci v&yacute;pisu je pozn&aacute;mka s č&iacute;slem ř&iacute;zen&iacute;, na jehož z&aacute;kladě byla plomba vyznačena.</p>
    <hr /><h2>Geometrick&yacute; pl&aacute;n</h2>
    <p><strong>Geometrick&yacute; pl&aacute;n (GP)</strong> je neoddělitelnou souč&aacute;st&iacute; listiny, podle kter&eacute; m&aacute; b&yacute;t proveden z&aacute;pis do katastru. A to v&nbsp;př&iacute;padech, je-li třeba předmět z&aacute;pisu zobrazit do katastr&aacute;ln&iacute; mapy, m&aacute;-li b&yacute;t zpřesněno jeho geometrick&eacute; a polohov&eacute; určen&iacute; nebo byl-li průběh hranice určen soudem. Geometrick&yacute; pl&aacute;n mus&iacute; b&yacute;t ověřen, že sv&yacute;mi n&aacute;ležitostmi a přesnost&iacute; odpov&iacute;d&aacute; platn&yacute;m pr&aacute;vn&iacute;m předpisům, a opatřen souhlasem katastr&aacute;ln&iacute;ho &uacute;řadu s oč&iacute;slov&aacute;n&iacute;m parcel.</p>
    <p>N&aacute;ležitostmi geometrick&eacute;ho pl&aacute;nu jsou dle z&aacute;kona:</p>
    <ul>
    <li>popisov&eacute; pole,</li>
    <li>grafick&eacute; zn&aacute;zorněn&iacute;,</li>
    <li>v&yacute;kaz dosavadn&iacute;ho a nov&eacute;ho stavu &uacute;dajů katastru,</li>
    <li>seznam souřadnic.</li>
    </ul>
    <p>V&nbsp;grafick&eacute;m zn&aacute;zorněn&iacute; (n&aacute;črtu) <strong>z&aacute;znamu podrobn&eacute;ho měřen&iacute; změn (ZPMZ)</strong> je dosavadn&iacute; stav vyhotoven černou barvou a nov&yacute; stav barvou červenou.</p>


    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Katastr nemovitostí stručně</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/vysilane-signaly-gnss/"><button className="inv">&larr; Vysílané signály GNSS (GPS)</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
